import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from 'app/providers/StoreProvider';

import { DeliveryInstructions, Property } from '../../types/PropertySchema';

interface UpdateDeliveryInstructionsServiceParams {
    propertyId: string;
    instructions: DeliveryInstructions;
}

export const updateDeliveryInstructions = createAsyncThunk<
    Property,
    UpdateDeliveryInstructionsServiceParams,
    ThunkConfig<string>
>(
    'property/updateDeliveryInstructions',
    async ({ propertyId, instructions }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.patch<Property>(
                `/v1/properties/${propertyId}`,
                instructions,
            );

            return response.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
