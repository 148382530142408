import { Box } from '@mui/system';
import { memo, useMemo } from 'react';

import { ContextMenu } from 'shared/ui/ContextMenu/ContextMenu';

interface CategoryContextMenuProps {
    onEdit: () => void;
}

export const CategoryContextMenu = memo(
    ({ onEdit }: CategoryContextMenuProps) => {
        const listOfActions = useMemo(
            () => [{ label: 'Edit', onClick: onEdit }],
            [onEdit],
        );

        return (
            <Box>
                <ContextMenu
                    list={listOfActions}
                    forceClickOnSingleItem={true}
                />
            </Box>
        );
    },
);
