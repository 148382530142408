import { SxProps, TextField } from '@mui/material';
import { useField } from 'formik';
import { FocusEventHandler, MutableRefObject } from 'react';

interface FormikInputProps {
    name: string;
    label: string;
    placeholder?: string;
    disabled?: boolean;
    hasError?: boolean;
    readOnly?: boolean;
    loading?: boolean;
    minimized?: boolean;
    maxLength?: number;
    validateOnTouch?: boolean;
    maskRef?: MutableRefObject<HTMLInputElement | null>;
    onBlurAction?: () => void;
    onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
    styleOverride?: SxProps;
}

const minimizedStyle = {
    '& .MuiInputBase-root': {
        height: '40px',
        position: 'relative',
    },
    '& label': {
        top: '-8px',
    },
    '& .MuiInputBase-root input': {
        width: 'calc(100% - 48px)',
    },
};

const FormikInput = ({
    name,
    label,
    placeholder,
    disabled,
    readOnly,
    hasError,
    loading,
    minimized,
    maxLength,
    validateOnTouch = true,
    maskRef = null,
    onBlurAction,
    onClick,
    styleOverride = {},
}: FormikInputProps) => {
    const [field, { touched, error }] = useField(name);

    const blur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
        e,
    ) => {
        if (onBlurAction) {
            onBlurAction();
        }
        field.onBlur(e);
    };

    const style = minimized
        ? { ...minimizedStyle, ...styleOverride }
        : styleOverride;

    // Note regarding `validateOnTouch`, as part of working with Blake on forms, we are going to change how form validation presents
    // I added `validateOnTouch` defaulting to true to not impact older areas of the code until we update them in their respective tickets
    // `validateOnTouch` will disappear shortly with that in mind
    return (
        <TextField
            data-testid={`Formik-input-${name}`}
            sx={style}
            id={name}
            disabled={loading || disabled}
            label={label}
            placeholder={placeholder}
            variant="outlined"
            fullWidth
            value={field.value || ''}
            onChange={field.onChange}
            onBlur={blur}
            onClick={onClick}
            error={(validateOnTouch && touched && Boolean(error)) || hasError}
            helperText={(validateOnTouch && touched) || hasError ? error : ''}
            inputProps={{
                ref: maskRef,
                maxLength: maxLength || '',
                readOnly,
            }}
        />
    );
};

export default FormikInput;
