export type DataObject = { [key: string]: string };

export const blankValue = '⠀';

export const fulfillBlankValues = (
    dataObject: DataObject,
    keysToFulfill: string | string[],
): DataObject => {
    const keys = Array.isArray(keysToFulfill) ? keysToFulfill : [keysToFulfill];

    return keys.reduce((acc, key) => {
        if (acc[key] === '') {
            acc[key] = blankValue;
        }

        return acc;
    }, dataObject);
};

export const cleanBlankValues = (dataObject: DataObject): DataObject => {
    return Object.keys(dataObject).reduce((acc, key) => {
        acc[key] = cleanBlankValue(acc[key]);

        return acc;
    }, dataObject);
};

export const cleanBlankValue = (value: string): string => {
    return value?.replaceAll(blankValue, '');
};
