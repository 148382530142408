import { CheckOutlined } from '@mui/icons-material';
import { Box, Radio, Typography, alpha } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Order } from 'entities/Order/model/types/OrderSchema';
import {
    Proposal,
    SelectedProposal,
} from 'entities/Proposal/model/types/ProposalSchema';
import { getUserCanSeePrices } from 'entities/User/model/selectors/userPermissionsSeletors';
import { OrderPriceDisplay } from 'shared/ui/OrderPriceDisplay/OrderPriceDisplay';

interface OrderViewItemDetailedSuppliersRadioProps {
    order: Order;
    proposals: Proposal[];
    selectedProposal?: SelectedProposal;
    selectedProposalId: string;
    onChange: (p: Proposal) => void;
    disabled: boolean;
}

const subtextColor = '#828282';

export const OrderViewItemDetailedSuppliersRadio = ({
    order,
    proposals,
    selectedProposal,
    selectedProposalId,
    onChange,
    disabled,
}: OrderViewItemDetailedSuppliersRadioProps) => {
    const { t } = useTranslation();

    const canSeePrices = useSelector(getUserCanSeePrices);

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap="8px"
            paddingBottom="8px"
        >
            {proposals?.length > 0 &&
                proposals.map((p) => {
                    // Since we do NOT have `lastPriceUpdate` on proposals, we will only use it for the selected proposal (which has it)
                    // In production, we only have one real supplier... so this will work fine for customers in the real world
                    // For demos, we will end up just always showing the price for other proposals regardless of the price validity
                    // This will need to be addressed in the multi-supplier world when we get there
                    let priceLastUpdated = undefined;
                    if (p.uid === selectedProposalId) {
                        priceLastUpdated = selectedProposal.lastPriceUpdate;
                    }

                    return (
                        <Box
                            sx={{
                                boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
                            }}
                            key={p?.seller?.uid}
                            display="flex"
                            borderRadius="4px"
                            data-testclass="order-item-supplier"
                        >
                            <Box
                                width="60px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                bgcolor={'rgba(63, 110, 183, 0.10)'}
                            >
                                <Radio
                                    data-testclass="radio-chose-supplier"
                                    disabled={disabled || p?.isOutOfStock}
                                    checked={p.uid === selectedProposalId}
                                    onChange={() => onChange(p)}
                                    value={p?.uid}
                                />
                            </Box>
                            <Box
                                width="100%"
                                p="12px 16px"
                                flex="1 1"
                                sx={
                                    p?.isOutOfStock
                                        ? {
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              alignItems: 'center',
                                          }
                                        : {}
                                }
                                color={subtextColor}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignContent="center"
                                    gap="10px"
                                    mb={p?.isOutOfStock ? '' : '6px'}
                                >
                                    <Typography
                                        typography="openSans.subtitle1Medium"
                                        sx={(theme) => ({
                                            color: alpha(
                                                theme.palette.common?.black,
                                                0.85,
                                            ),
                                        })}
                                        data-testclass="order-item-supplier-name"
                                    >
                                        {p.seller.name}
                                    </Typography>
                                    {p?.isPreferred && (
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            gap="4px"
                                            alignItems="start"
                                            sx={{
                                                padding: '3px 6px',
                                                backgroundColor: '#DBEFE1',
                                                borderRadius: '2px',
                                                color: '#000000',
                                            }}
                                            data-testclass="order-item-supplier-label"
                                        >
                                            <Typography typography="openSans.caption">
                                                {t('Preferred')}
                                            </Typography>
                                            <CheckOutlined
                                                sx={{
                                                    width: '16px',
                                                    height: '16px',
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Box>

                                {p?.isOutOfStock ? (
                                    <Typography
                                        typography="openSans.subtitle2"
                                        sx={{ color: '#BF1B28' }}
                                        data-testclass="order-item-supplier-price"
                                    >
                                        {t('Out of stock')}
                                    </Typography>
                                ) : (
                                    <>
                                        {canSeePrices && (
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                mb="2px"
                                            >
                                                <Typography typography="openSans.body2">
                                                    {t('Total')} (
                                                    {p.quantity || 0}
                                                    ):
                                                </Typography>

                                                <OrderPriceDisplay
                                                    containerHeight="32px"
                                                    typography="openSans.h5"
                                                    containerProps={(theme: {
                                                        palette: {
                                                            common: {
                                                                black: string;
                                                            };
                                                        };
                                                    }) => ({
                                                        color: alpha(
                                                            theme.palette.common
                                                                ?.black,
                                                            0.85,
                                                        ),
                                                    })}
                                                    testClass="order-item-supplier-price"
                                                    price={p.prices.total}
                                                    priceLastUpdate={
                                                        priceLastUpdated
                                                    }
                                                    order={order}
                                                />
                                            </Box>
                                        )}
                                    </>
                                )}
                            </Box>
                        </Box>
                    );
                })}
        </Box>
    );
};
