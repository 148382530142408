import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UserRole, getUserRole } from 'entities/User';
import { getRouteSettingsOrdersDelivery } from 'shared/const/router';
import { Tooltip } from 'shared/ui/Tooltip';

interface TooltipProps {
    propertyId: string;
}

export default function ({ propertyId }: TooltipProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const currentUserRole = useSelector(getUserRole);

    const canDeliverySettings = [
        UserRole.PMC_ADMIN,
        UserRole.PROPERTY_MANAGER,
    ].includes(currentUserRole);

    return (
        <Tooltip
            isSecondaryColor
            content={
                <>
                    <Typography typography="openSans.caption">
                        {t(
                            `For property deliveries, add the appropriate contact details ` +
                                `and instructions to ensure smooth deliveries to the correct location.`,
                        )}
                    </Typography>
                    {canDeliverySettings && (
                        <Typography
                            typography="openSans.caption"
                            sx={{ mt: '16px' }}
                        >
                            {t(`Edit defaults in `)}
                            <Typography
                                typography="openSans.caption"
                                component="span"
                                color="primary"
                                onClick={() => {
                                    navigate(
                                        getRouteSettingsOrdersDelivery(
                                            propertyId,
                                            'operations',
                                        ),
                                    );
                                }}
                                sx={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                }}
                            >
                                {t('Settings')}
                            </Typography>
                            .
                        </Typography>
                    )}
                </>
            }
        />
    );
}
