import { TextField } from '@mui/material';
import { useField } from 'formik';
import React, { FocusEventHandler, MutableRefObject } from 'react';

interface InlineFormikInputProps {
    name: string;
    label: string;
    disabled?: boolean;
    hasError?: boolean;
    maxLength?: number;
    validateOnTouch?: boolean;
    maskRef?: MutableRefObject<HTMLInputElement | null>;
    onBlurAction?: () => void;
    multiline?: boolean;
    onKeyPress?: (e: React.KeyboardEvent) => void;
}

const disabledStyle = {
    '& .MuiInput-root:before': {
        borderBottom: '1px solid #E9E8E8 !important',
    },
    '& .MuiInput-root:after': {
        borderBottom: 'none !important',
    },
};

const InlineFormikInput = ({
    name,
    label,
    maxLength,
    hasError,
    disabled,
    validateOnTouch = true,
    maskRef = null,
    onBlurAction,
    multiline = false,
    onKeyPress,
}: InlineFormikInputProps) => {
    const [field, { touched, error }] = useField(name);

    const blur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
        e,
    ) => {
        if (onBlurAction) {
            onBlurAction();
        }
        field.onBlur(e);
    };

    // Note regarding `validateOnTouch`, as part of working with Blake on forms, we are going to change how form validation presents
    // I added `validateOnTouch` defaulting to true to not impact older areas of the code until we update them in their respective tickets
    // `validateOnTouch` will disappear shortly with that in mind
    return (
        <TextField
            data-testid={`Formik-input-${name}`}
            id={name}
            label={label}
            disabled={disabled}
            variant="standard"
            fullWidth
            multiline={multiline}
            value={field.value || ''}
            onChange={field.onChange}
            onBlur={blur}
            onKeyPress={onKeyPress}
            error={(validateOnTouch && touched && Boolean(error)) || hasError}
            helperText={(validateOnTouch && touched) || hasError ? error : ''}
            inputProps={{
                ref: maskRef,
                maxLength: maxLength || '',
            }}
            sx={{
                '& .MuiInputBase-input': {
                    backgroundColor: 'transparent !important',
                    padding: '8px 0 12px',
                },
                '&': {
                    marginBottom: '12px',
                },
                ...(disabled ? disabledStyle : {}),
            }}
        />
    );
};

export default InlineFormikInput;
