export const getPrice = (price: string | number | null): string => {
    if (price === null) {
        return 'Unavailable';
    }

    const num = typeof price === 'string' ? parseFloat(price) : price;

    if (isNaN(num) || num === 0) {
        return 'Unavailable';
    }

    return `$${num.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })}`;
};
