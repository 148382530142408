import { orderStatusesSupportingPriceChecks } from 'entities/Order/model/consts/orderStatuses';
import { Order } from 'entities/Order/model/types/OrderSchema';

export const orderCanShowPriceLoading = (order: Order) => {
    if (order) {
        // Once we make it out of waiting approval, always show the price regardless of the status
        if (!orderStatusesSupportingPriceChecks.includes(order.status))
            return false;

        return true;
    }
    return false;
};
