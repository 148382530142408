import {
    CheckOutlined,
    Clear,
    ModeEditOutlineOutlined,
} from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { MutableRefObject } from 'react';

import InlineFormikInput from 'shared/ui/Formik/FormikInput/InlineFormikInput';

interface InputProps {
    readOnly: boolean;
    inputProps: {
        name: string;
        label: string;
        mask?: MutableRefObject<HTMLInputElement>;
        onKeyPress?: (e: React.KeyboardEvent) => void;
    };
    active: boolean;
    onClick: () => void;
    submitCallback: () => void;
    cancelCallback: () => void;
    multiline?: boolean;
    maxLength?: number;
}

export default function ({
    readOnly,
    inputProps,
    active,
    onClick,
    submitCallback,
    cancelCallback,
    multiline = false,
    maxLength,
}: InputProps) {
    const iconStyle = {
        fontSize: '20px !important',
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                '& > button.edit-button': {
                    opacity: 0,
                },
                '&:hover > button.edit-button': {
                    opacity: 1,
                },
            }}
        >
            <InlineFormikInput
                label={inputProps.label}
                onKeyPress={inputProps.onKeyPress}
                disabled={!active}
                name={inputProps.name}
                maskRef={inputProps.mask}
                multiline={multiline}
                maxLength={maxLength}
            />
            {!readOnly && (
                <>
                    {!active ? (
                        <IconButton
                            className={'edit-button'}
                            onClick={onClick}
                            color="primary"
                        >
                            <ModeEditOutlineOutlined sx={iconStyle} />
                        </IconButton>
                    ) : (
                        <>
                            <IconButton
                                onClick={cancelCallback}
                                color="primary"
                            >
                                <Clear sx={iconStyle} />
                            </IconButton>
                            <IconButton
                                onClick={submitCallback}
                                color="primary"
                            >
                                <CheckOutlined sx={iconStyle} />
                            </IconButton>
                        </>
                    )}
                </>
            )}
        </Box>
    );
}
