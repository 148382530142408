import { ArrowDropDown } from '@mui/icons-material';
import { AccordionSummary, Box, Typography, alpha } from '@mui/material';
import { ReactNode } from 'react';

export const AccordionHeader = ({
    title,
    suffix,
}: {
    title: string;
    suffix?: ReactNode;
}) => {
    return (
        <AccordionSummary
            sx={{
                m: '8px 0',
            }}
            expandIcon={
                <ArrowDropDown
                    sx={(theme) => ({
                        color: alpha(theme.palette.common?.black, 0.3),
                    })}
                />
            }
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '8px',
                }}
            >
                <Typography typography="openSans.subtitle1Medium">
                    {title}
                </Typography>
                {suffix}
            </Box>
        </AccordionSummary>
    );
};
