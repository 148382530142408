import { Box, Skeleton } from '@mui/material';

import { orderCanShowPriceLoading } from 'entities/Order/lib/orderCanShowPriceLoading/orderCanShowPriceLoading';
import { Order } from 'entities/Order/model/types/OrderSchema';
import { getPrice } from 'shared/lib/getPrice/getPrice';

interface OrderPriceDisplayProps {
    width?: string;
    containerHeight?: string;
    containerProps?: object;
    justifyContent?: string;
    typography?: string;
    price: string | number | null;
    priceLastUpdate?: string | null;
    order?: Order;
    orderHasLoadingPrices?: boolean;
    testClass?: string;
    testId?: string;
}

// `priceLastUpdate` is used when you're displaying a price for a singular item that is NOT dependant on other prices in an order
// `orderHasLoadingPrices` is all about when you're displaying a price for something that IS dependant on other prices in the order (such as totals and taxes)

export const OrderPriceDisplay = ({
    width = '100px',
    containerHeight = '20px',
    containerProps,
    price,
    priceLastUpdate,
    orderHasLoadingPrices = undefined,
    justifyContent,
    typography,
    order,
    testClass,
    testId,
}: OrderPriceDisplayProps) => {
    const priceIsUpdating =
        (typeof priceLastUpdate !== 'undefined' && !priceLastUpdate) ||
        (typeof orderHasLoadingPrices !== 'undefined' && orderHasLoadingPrices);

    const showSkeleton = priceIsUpdating && orderCanShowPriceLoading(order);

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent={justifyContent}
            height={containerHeight}
            data-testclass={testClass}
            data-testid={testId}
            typography={typography}
            sx={containerProps}
        >
            {showSkeleton ? (
                <Skeleton
                    variant="rounded"
                    animation="wave"
                    width={width}
                    height="12px"
                    sx={{
                        '&::after': {
                            animationDuration: '1.5s',
                            background:
                                'linear-gradient(90deg,transparent,#DCDCDC,transparent)',
                        },
                        backgroundColor: '#F1F1F1',
                        borderRadius: '6px',
                    }}
                />
            ) : (
                getPrice(price)
            )}
        </Box>
    );
};
