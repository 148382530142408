import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const buildTimestamp = process.env.BUILD_TIMESTAMP;

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        // debug: __IS_DEV__,
        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        backend: {
            loadPath: `/locales/{{lng}}/{{ns}}.json?v=${buildTimestamp}`,
        },
    });

export default i18n;
