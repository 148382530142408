import { Order } from 'entities/Order/model/types/OrderSchema';
import { isProposalInPriceCheck } from 'entities/Proposal/lib/isProposalInPriceCheck';

import { orderCanShowPriceLoading } from '../orderCanShowPriceLoading/orderCanShowPriceLoading';

export const orderHasPendingPriceChecks = function (order: Order) {
    if (!orderCanShowPriceLoading(order)) return false;

    const items = order?.items;

    if (items.length > 0) {
        const itemsAwaitingPrices = items.some((item) =>
            isProposalInPriceCheck(item?.selectedProposal),
        );

        if (itemsAwaitingPrices) return true;
    }

    return false;
};
