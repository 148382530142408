import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from 'app/providers/StoreProvider';
import {
    DeliveryInstructionsSchema,
    Order,
} from 'entities/Order/model/types/OrderSchema';
import {
    DataObject,
    fulfillBlankValues,
} from 'shared/lib/blankValue/blankValue';

interface DeliveryInstructionsUpdateSchema {
    orderId: string;
    deliveryInstructions: DeliveryInstructionsSchema;
}

export const editOrderDeliveryInstructions = createAsyncThunk<
    Order,
    DeliveryInstructionsUpdateSchema,
    ThunkConfig<string>
>(
    'order/editPurchaseOrderNumber',
    async ({ orderId, deliveryInstructions }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.patch<Order>(
                `/v1/orders/${orderId}/delivery-instructions`,
                {
                    ...fulfillBlankValues(
                        deliveryInstructions as unknown as DataObject,
                        'delInstructions',
                    ),
                },
            );

            return response.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
