import { createSelector } from 'reselect';

import {
    getCatalogItems,
    getSelectedCatalogItems,
} from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { isProposalInPriceCheck } from 'entities/Proposal/lib/isProposalInPriceCheck';

export const getCurrentOrderSummary = createSelector(
    [getCatalogItems, getSelectedCatalogItems],
    (catalogItems, selectedItems) => {
        let totalPrice = 0;
        let hasLoadingPrices = false;

        catalogItems?.forEach((item) => {
            if ('price' in item) {
                const pricePerItem = item?.price;
                totalPrice += pricePerItem * selectedItems?.[item.id]?.qty;
                if (isProposalInPriceCheck(item?.selectedProposal))
                    hasLoadingPrices = true;
            }
        });

        return {
            totalPrice,
            hasLoadingPrices,
        };
    },
);
