/* eslint-disable max-len */
import { ModeEditOutlineOutlined } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    Box,
    Button,
    Dialog,
    IconButton,
    TextField,
    Typography,
    alpha,
} from '@mui/material';
import { useMask } from '@react-input/mask';
import { Field, Formik, FormikHelpers } from 'formik';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { fetchOrder } from 'entities/Order';
import { getUserCanEditDeliveryInstructions } from 'entities/Order/model/selectors/ordersPermissions';
import {
    DeliveryInstructionsSchema,
    Order,
} from 'entities/Order/model/types/OrderSchema';
import { editOrderDeliveryInstructions } from 'pages/OrderViewPage/model/services/editOrderDeliveryInstructions';
import DeliveryInstructionsTooltip from 'pages/OrderViewPage/ui/OrderViewPage/Components/DeliveryInstructionsTooltip';
import {
    INSTRUCTIONS_MAX_LENGTH,
    NAME_MAX_LENGTH,
} from 'pages/SettingsOrdersDeliveryPage/ui/SettingsOrdersDelivery';
import { getRouteNotFound } from 'shared/const/router';
import { cleanBlankValue } from 'shared/lib/blankValue/blankValue';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { AccordionHeader } from 'shared/ui/AccordionHeader/AccordionHeader';
import { DialogBoxContent } from 'shared/ui/DialogBoxContent/DialogBoxContent';
import InlineEditInput from 'shared/ui/InlineEditInput/InlineEditInput';

export const OrderDeliveryInstructions = ({ order }: { order: Order }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const editable = useSelector(getUserCanEditDeliveryInstructions);

    const [editing, setEditing] = useState({
        name: false,
        phone: false,
        instructions: false,
    });
    const redirectOnNotFound = useCallback(
        () => navigate(getRouteNotFound()),
        [navigate],
    );

    const inputPhoneRef = useMask({
        mask: '(___) ___-____',
        replacement: { _: /\d/ },
    });

    const delInstruction = cleanBlankValue(order.delInstructions);

    const DeliveryInfoSchema = Yup.object().shape({
        delRecipientName: Yup.string()
            .required('Contact name is required')
            .max(
                NAME_MAX_LENGTH,
                `Contact name cannot be longer than ${NAME_MAX_LENGTH} characters`,
            ),
        delRecipientPhone: Yup.string()
            .required('Phone number is required and must be valid')
            .test(
                'valid-phone-number',
                'Please enter a valid phone number e.g. (223) 532-1234',
                (value) => {
                    value = value || '';
                    return (
                        value === '' ||
                        (isPossiblePhoneNumber(value, 'US') &&
                            isValidPhoneNumber(value, 'US'))
                    );
                },
            ),
        delInstructions: Yup.string()
            .max(
                INSTRUCTIONS_MAX_LENGTH,
                `Must be ${INSTRUCTIONS_MAX_LENGTH} characters or less`,
            )
            .notRequired(),
    });

    const handleSubmit = async (
        values: DeliveryInstructionsSchema,
        helpers: FormikHelpers<DeliveryInstructionsSchema>,
    ): Promise<void> => {
        setEditing({
            name: false,
            phone: false,
            instructions: false,
        });
        const response = await dispatch(
            editOrderDeliveryInstructions({
                orderId: order.id,
                deliveryInstructions: values,
            }),
        );

        if (response.meta.requestStatus !== 'fulfilled') {
            toast(t('Unknown Error'));
            helpers.resetForm();
        } else {
            await dispatch(
                fetchOrder({
                    id: order.id,
                    onNotFound: redirectOnNotFound,
                }),
            );
        }
    };

    return (
        <Box sx={{ my: '16px' }}>
            <Accordion
                sx={(theme) => ({
                    bgcolor: alpha(theme.palette.grey[500], 0.5),
                    borderRadius: '12px',
                })}
            >
                <AccordionHeader
                    title={t('Delivery instructions')}
                    suffix={
                        editable ? (
                            <DeliveryInstructionsTooltip
                                propertyId={order.property}
                            />
                        ) : null
                    }
                />
                <AccordionDetails sx={{ p: '0 16px 12px' }}>
                    <Formik
                        initialValues={{
                            delRecipientName: order.delRecipientName,
                            delRecipientPhone: order.delRecipientPhone,
                            delInstructions: cleanBlankValue(
                                order.delInstructions,
                            ),
                        }}
                        enableReinitialize={true}
                        onSubmit={handleSubmit}
                        validationSchema={DeliveryInfoSchema}
                    >
                        {({ handleSubmit, resetForm }) => (
                            <>
                                <InlineEditInput
                                    readOnly={!editable}
                                    active={editing.name}
                                    multiline={true}
                                    maxLength={NAME_MAX_LENGTH}
                                    inputProps={{
                                        label: t('Contact'),
                                        name: 'delRecipientName',
                                        onKeyPress: (e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                            }
                                        },
                                    }}
                                    cancelCallback={() => {
                                        resetForm();
                                        setEditing({
                                            name: false,
                                            phone: false,
                                            instructions: false,
                                        });
                                    }}
                                    submitCallback={handleSubmit}
                                    onClick={() => {
                                        resetForm();
                                        setEditing({
                                            name: true,
                                            phone: false,
                                            instructions: false,
                                        });
                                    }}
                                />
                                <InlineEditInput
                                    readOnly={!editable}
                                    active={editing.phone}
                                    inputProps={{
                                        label: t('Phone'),
                                        name: 'delRecipientPhone',
                                        mask: inputPhoneRef,
                                    }}
                                    cancelCallback={() => {
                                        resetForm();
                                        setEditing({
                                            name: false,
                                            phone: false,
                                            instructions: false,
                                        });
                                    }}
                                    submitCallback={handleSubmit}
                                    onClick={() => {
                                        resetForm();
                                        setEditing({
                                            name: false,
                                            phone: true,
                                            instructions: false,
                                        });
                                    }}
                                />
                                {(editable || !!delInstruction) && (
                                    <Box sx={{ paddingBottom: '16px' }}>
                                        <Typography
                                            sx={{
                                                color: 'rgba(0, 0, 0, 0.6)',
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                lineHeight: '1.4375em',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {t('Instructions')}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                '& > button.edit-button': {
                                                    opacity: 0,
                                                },
                                                '&:hover > button.edit-button':
                                                    {
                                                        opacity: editable
                                                            ? 1
                                                            : 0,
                                                    },
                                                cursor: 'pointer',
                                                marginRight: editable
                                                    ? '36px'
                                                    : '0',
                                                borderBottom: delInstruction
                                                    ? 0
                                                    : '1px solid #E9E8E8 !important;',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: delInstruction
                                                        ? 'rgba(0, 0, 0, 1)'
                                                        : 'rgba(0, 0, 0, 0.35)',
                                                    overflowWrap: 'anywhere',
                                                }}
                                            >
                                                {delInstruction ||
                                                    t('Add instructions')}
                                            </Typography>
                                            <IconButton
                                                className={'edit-button'}
                                                color="primary"
                                                onClick={() =>
                                                    setEditing({
                                                        name: false,
                                                        phone: false,
                                                        instructions: true,
                                                    })
                                                }
                                                sx={{
                                                    marginRight: '-36px',
                                                }}
                                            >
                                                <ModeEditOutlineOutlined
                                                    sx={{
                                                        fontSize:
                                                            '20px !important',
                                                    }}
                                                />
                                            </IconButton>
                                        </Box>
                                        <Dialog open={editing.instructions}>
                                            <DialogBoxContent
                                                onClose={() => {
                                                    resetForm();
                                                    setEditing({
                                                        name: false,
                                                        phone: false,
                                                        instructions: false,
                                                    });
                                                }}
                                                buttons={
                                                    <Box
                                                        display="flex"
                                                        justifyContent="flex-end"
                                                        width="100%"
                                                        gap="10px"
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                resetForm();
                                                                setEditing({
                                                                    name: false,
                                                                    phone: false,
                                                                    instructions:
                                                                        false,
                                                                });
                                                            }}
                                                            color="primary"
                                                        >
                                                            {t('Cancel')}
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => {
                                                                handleSubmit();
                                                                setEditing({
                                                                    name: false,
                                                                    phone: false,
                                                                    instructions:
                                                                        false,
                                                                });
                                                            }}
                                                        >
                                                            {t(
                                                                'Save instructions',
                                                            )}
                                                        </Button>
                                                    </Box>
                                                }
                                            >
                                                <Box maxWidth="440px">
                                                    <Typography
                                                        mb="30px"
                                                        typography="poppins.subtitle1"
                                                    >
                                                        {t(
                                                            'Delivery instructions',
                                                        )}
                                                    </Typography>
                                                    <Typography
                                                        mb="36px"
                                                        typography="openSans.body2"
                                                    >
                                                        {`Add clear delivery instructions for suppliers and drivers.`}
                                                    </Typography>
                                                </Box>
                                                <Field
                                                    as={TextField}
                                                    name={'delInstructions'}
                                                    placeholder={t(
                                                        'Delivery instructions placeholder',
                                                    )}
                                                    label={t('Instructions')}
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    inputProps={{
                                                        maxLength:
                                                            INSTRUCTIONS_MAX_LENGTH,
                                                    }}
                                                />
                                            </DialogBoxContent>
                                        </Dialog>
                                    </Box>
                                )}
                            </>
                        )}
                    </Formik>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};
