import { Box } from '@mui/material';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { orderHasPriceLoading } from 'entities/Order/lib/orderHasPriceLoading/orderHasPriceLoading';
import {
    INITIAL_PRICE_POLLING_TIME,
    MAX_POLLING_TIME,
    SUBSEQUENT_PRICE_POLLING_TIME,
} from 'entities/Order/model/consts/orderPollingTiming';
import {
    OrderStatuses,
    getOrderStatusForRoute,
    getOrderStatusFromRoute,
    orderStatusesSupportingPriceChecks,
} from 'entities/Order/model/consts/orderStatuses';
import { fetchOrders } from 'entities/Order/model/services/fetchOrders/fetchOrders';
import { orderActions } from 'entities/Order/model/slices/OrderSlice';
import { Order } from 'entities/Order/model/types/OrderSchema';
import { getSelectedPropertyId } from 'entities/Property/model/selectors/PropertySelectors';
import { getRouteOrdersTableByStatus } from 'shared/const/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useInitialEffect } from 'shared/lib/hooks/useInitialEffect';
import { debounce } from 'shared/lib/lodash/lodash';

import { OrdersTableSearch } from './OrdersTableSearch';
import { OrdersTableTabs } from './OrdersTableTabs';

import {
    getOrdersCurrentPage,
    getOrdersPageSize,
    getOrdersTableSorting,
    orderTableIsInitialLoaded,
} from '../model/selectors/catalogItemSelectors';
import { fetchOrdersCounters } from '../model/services/fetchOrdersCounters/fetchOrdersCounters';
import { orderTableActions } from '../model/slices/OrderTableSlice';

const ordersHavePendingPriceChecks = function (
    status: OrderStatuses,
    orders: Order[],
) {
    if (!orderStatusesSupportingPriceChecks.includes(status)) return false;

    if (orders.length > 0) {
        const ordersAwaitingPrices = orders.some((order) =>
            orderHasPriceLoading(order),
        );

        if (ordersAwaitingPrices) return true;
    }

    return false;
};

export const OrdersTable = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const selectedPropertyId = useSelector(getSelectedPropertyId);
    const isInitialLoaded = useSelector(orderTableIsInitialLoaded);
    const currentPage = useSelector(getOrdersCurrentPage);
    const pageSize = useSelector(getOrdersPageSize);
    const sorting = useSelector(getOrdersTableSorting);

    const defaultOrderStatus = OrderStatuses.DRAFT;

    // Price Polling Props //
    const inPricePollingRef = useRef(false);
    const priceTimeoutRef = useRef(null);
    const pollingStartTimeRef = useRef(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetchTableData = useCallback(
        debounce(async (fetchParams, propertyId) => {
            const response = await dispatch(fetchOrders(fetchParams)).unwrap();
            await dispatch(fetchOrdersCounters(propertyId));

            // If there are any orders that have pending price checks, we will poll to ensure we update the UI when the prices are loaded
            if (
                ordersHavePendingPriceChecks(
                    fetchParams.status,
                    response.results,
                )
            ) {
                const delay = getEarliestInitialDelay(response.results);
                if (delay) {
                    pollForPricingUpdates(delay);
                } else {
                    clearPricePolling();
                }
            } else {
                clearPricePolling();
            }

            if (!isInitialLoaded) {
                dispatch(orderTableActions.setInitialLoaded(true));
            }
        }, 500),
        [params],
    );

    useInitialEffect(() => {
        let orderStatus = defaultOrderStatus;

        if (params?.orderStatus) {
            orderStatus = getOrderStatusFromRoute(params.orderStatus);
        }

        navigate(
            getRouteOrdersTableByStatus(
                params.propertyId,
                getOrderStatusForRoute(orderStatus),
            ),
        );
        dispatch(orderActions.setSearchParamsStatus(orderStatus));
    });

    const fetchTableData = useCallback(async () => {
        const orderStatusParamFromRoute = getOrderStatusFromRoute(
            params?.orderStatus,
        );

        const dataParams = {
            propertyId: selectedPropertyId,
            status: orderStatusParamFromRoute,
            pagination: {
                currentPage,
                pageSize,
            },
            sorting,
        };

        if (selectedPropertyId && orderStatusParamFromRoute) {
            await debouncedFetchTableData(dataParams, selectedPropertyId);
        }
    }, [
        params?.orderStatus,
        selectedPropertyId,
        currentPage,
        pageSize,
        sorting,
        debouncedFetchTableData,
    ]);

    const getEarliestInitialDelay = function (orders: Order[]) {
        // Find the lowest delay between all pending orders to be our setTimeout
        const nowTime = Date.now();

        const delays = orders
            .filter((order) => orderHasPriceLoading(order) && order.updatedAt)
            .map((order) => {
                // Return the delay as a diff from when the order was updated if below the initial timing, else ise use the default subsequent timing
                const updatedAtTime = new Date(order.updatedAt).getTime();
                const elapsedTime = nowTime - updatedAtTime;

                return elapsedTime < INITIAL_PRICE_POLLING_TIME
                    ? INITIAL_PRICE_POLLING_TIME - elapsedTime
                    : SUBSEQUENT_PRICE_POLLING_TIME;
            });

        return delays.length > 0 ? Math.min(...delays) : null;
    };

    const clearPricePolling = useCallback(() => {
        if (priceTimeoutRef.current) {
            clearInterval(priceTimeoutRef.current);
            priceTimeoutRef.current = null;
            inPricePollingRef.current = false;
        }
    }, []);

    const pollForPricingUpdates = useCallback(
        (pollingTime: number) => {
            if (inPricePollingRef.current) return;

            inPricePollingRef.current = true;
            pollingStartTimeRef.current = Date.now();

            const checkPricing = async () => {
                const timePolling = Date.now() - pollingStartTimeRef.current;
                if (timePolling >= MAX_POLLING_TIME) {
                    console.warn(
                        `Stopping price polling due to maximum time limit polling reached...`,
                    );
                    clearPricePolling();
                    return;
                }

                inPricePollingRef.current = false;
                dispatch(fetchTableData);
            };

            priceTimeoutRef.current = setTimeout(checkPricing, pollingTime);
        },
        [clearPricePolling, dispatch, fetchTableData],
    );

    useEffect(
        () => () => {
            dispatch(orderTableActions.setInitialLoaded(false));
        },
        [dispatch],
    );

    useEffect(() => {
        fetchTableData();

        return () => {
            clearPricePolling();
        };
    }, [clearPricePolling, fetchTableData]);

    return (
        <Box>
            <Box mb="16px" style={{ display: 'none' }}>
                <OrdersTableSearch />
            </Box>
            <Box mb="16px">
                <OrdersTableTabs />
            </Box>
            <Outlet />
        </Box>
    );
};
