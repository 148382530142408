/* eslint-disable max-len */
import {
    Box,
    Button,
    FormControlLabel,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { useMask } from '@react-input/mask';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { boolean, object, string } from 'yup';

import { getSelectedProperty } from 'entities/Property/model/selectors/PropertySelectors';
import { updateDeliveryInstructions } from 'entities/Property/model/services/updateDeliveryInstructions/updateDeliveryInstructions';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import FormikInput from 'shared/ui/Formik/FormikInput/FormikInput';
import { Loader } from 'shared/ui/Loader/Loader';

export const NAME_MAX_LENGTH = 200;
export const INSTRUCTIONS_MAX_LENGTH = 500;

const deliveryValidationSchema = object({
    name: string()
        .max(NAME_MAX_LENGTH, `Must be ${NAME_MAX_LENGTH} characters or less`)
        .required('Contact name is required'),
    usePropertyPhone: boolean(),
    phone: string().when('usePropertyPhone', {
        is: false,
        then: (schema) =>
            schema.test(
                'phoneValidity',
                'Phone number is required and must be valid',
                (value) => {
                    return (
                        !!value &&
                        isPossiblePhoneNumber(value, 'US') &&
                        isValidPhoneNumber(value, 'US')
                    );
                },
            ),
        otherwise: (schema) => schema.notRequired(),
    }),
    instructions: string()
        .max(
            INSTRUCTIONS_MAX_LENGTH,
            `Must be ${INSTRUCTIONS_MAX_LENGTH} characters or less`,
        )
        .notRequired(),
});

interface SettingsOrdersDeliveryProps {
    name?: string;
    phone?: string;
    usePropertyPhone?: boolean;
    instructions?: string;
}

const SettingsOrdersDelivery = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const selectedProperty = useSelector(getSelectedProperty);

    const [loading, setLoading] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);

    const data = {
        usePropertyPhone:
            !selectedProperty?.delRecipientPhone ||
            selectedProperty?.delRecipientPhone.replace(/\D/g, '') ===
                selectedProperty?.propertyPhoneNumber
                    .toString()
                    .replace(/\D/g, ''),
        name: selectedProperty?.delRecipientName,
        phone: selectedProperty?.delRecipientPhone,
        instructions: selectedProperty?.delInstructions || '',
    };

    const inputPhoneRef = useMask({
        mask: '(___) ___-____',
        replacement: { _: /\d/ },
    });

    const handleSubmit = async (
        values: SettingsOrdersDeliveryProps,
        helpers: FormikHelpers<SettingsOrdersDeliveryProps>,
    ): Promise<void> => {
        setLoading(true);
        const response = await dispatch(
            updateDeliveryInstructions({
                propertyId: selectedProperty.id,
                instructions: {
                    delInstructions: values.instructions,
                    delRecipientPhone: values.usePropertyPhone
                        ? null
                        : values.phone,
                    delRecipientName: values.name,
                },
            }),
        );

        if (response.meta.requestStatus !== 'fulfilled') {
            toast(t('Unknown Error'));
        } else {
            toast(t('Changes saved successfully'));
            helpers.resetForm({ values });
        }

        setLoading(false);
        setSubmitClicked(false);
    };

    return (
        <Box>
            <Typography typography="poppins.h6" marginBottom="20px">
                {t(`Delivery instructions`)}
            </Typography>

            <Box data-testid="page-description" mb="40px">
                <Typography mt="10px" typography="openSans.body2">
                    {t('Delivery instructions info')}
                </Typography>
            </Box>

            <Formik
                initialValues={data}
                validationSchema={deliveryValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={true}
            >
                {({
                    values,
                    errors,
                    handleSubmit,
                    setFieldValue,
                    handleChange,
                    resetForm,
                    validateForm,
                    isValid,
                    dirty,
                }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Box maxWidth="660px">
                                <Box mb="30px" width="360px">
                                    <FormikInput
                                        name="name"
                                        label={t('Contact')}
                                        placeholder={t('Contact')}
                                        loading={loading}
                                        maxLength={NAME_MAX_LENGTH}
                                        validateOnTouch={false}
                                        hasError={
                                            submitClicked &&
                                            Boolean(errors?.name)
                                        }
                                    />
                                </Box>

                                <Box
                                    display="flex"
                                    flex="1"
                                    gap="24px"
                                    mb="30px"
                                    width="100%"
                                    alignItems="flex-start"
                                >
                                    <Box width="360px">
                                        <FormikInput
                                            name="phone"
                                            label={t('Phone')}
                                            placeholder={'(000) 000-0000'}
                                            loading={loading}
                                            disabled={values.usePropertyPhone}
                                            maskRef={inputPhoneRef}
                                            maxLength={14}
                                            validateOnTouch={false}
                                            hasError={
                                                submitClicked &&
                                                Boolean(errors?.phone)
                                            }
                                        />
                                    </Box>
                                    <FormControlLabel
                                        control={
                                            <Field
                                                as={Switch}
                                                name="usePropertyPhone"
                                                type="checkbox"
                                                disabled={loading}
                                                onChange={async (
                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                ) => {
                                                    const usePropertyPhone =
                                                        event.target.checked;

                                                    handleChange(event);

                                                    await setFieldValue(
                                                        'phone',
                                                        usePropertyPhone
                                                            ? selectedProperty.propertyPhoneNumber
                                                            : '',
                                                    );

                                                    if (!usePropertyPhone) {
                                                        const phoneInput: HTMLInputElement =
                                                            document.querySelector(
                                                                '#phone',
                                                            );
                                                        phoneInput?.focus();
                                                    }

                                                    validateForm();
                                                }}
                                            />
                                        }
                                        label={t('Use property phone number')}
                                        sx={{
                                            marginTop: '9px',
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '15px',
                                            },
                                        }}
                                    />
                                </Box>

                                <Field
                                    as={TextField}
                                    name="instructions"
                                    disabled={loading}
                                    placeholder={t(
                                        'Delivery instructions placeholder',
                                    )}
                                    label={t('Instructions')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    inputProps={{
                                        maxLength: INSTRUCTIONS_MAX_LENGTH,
                                    }}
                                />

                                <Box
                                    marginTop="20px"
                                    display={dirty ? 'flex' : 'none'}
                                    gap="20px"
                                    justifyContent="right"
                                >
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            resetForm();
                                            setSubmitClicked(false);
                                        }}
                                    >
                                        {t('Cancel')}
                                    </Button>
                                    <Button
                                        startIcon={
                                            loading && (
                                                <Loader color="inherit" />
                                            )
                                        }
                                        className={
                                            !isValid || !dirty || loading
                                                ? 'Mui-disabled'
                                                : ''
                                        }
                                        style={{
                                            pointerEvents: 'auto',
                                        }}
                                        variant="contained"
                                        type="submit"
                                        onClick={() => {
                                            setSubmitClicked(true);
                                        }}
                                    >
                                        {t(`Save`)}
                                    </Button>
                                </Box>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default SettingsOrdersDelivery;
