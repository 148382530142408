import { Checkbox } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { catalogItemActions } from 'entities/CatalogItem/model/slices/CatalogItemSlice';
import { fetchOrder } from 'entities/Order';
import { getSelectedOrderId } from 'entities/Order/model/selectors/ordersSelectors';
import { getUserCanEditPropertyCatalog } from 'entities/User/model/selectors/userPermissionsSeletors';
import { AddCatalogItemWithQty } from 'features/AddCatalogItemWithQty';
import { addItemsToOrderService } from 'features/AddItemsToOrder/model/services/addItemsToOrderService';
import { addItemsToOrderActions } from 'features/AddItemsToOrder/model/slices/addItemsToOrderSlice';
import { editOrderItem } from 'features/editItemsInOrder/model/services/editOrderItem/editOrderItem';
import { EditItemsInOrderRequestParams } from 'features/editItemsInOrder/model/types/editItemsInOrderSchema';
import { deleteOrderItem } from 'features/removeItemsInOrder/model/services/deleteOrderItem/deleteOrderItem';
import { getRouteNotFound } from 'shared/const/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useDebouncedCallback } from 'shared/lib/hooks/useDebouncedCallback';

interface CatalogItemCheckboxProps {
    isActive: boolean;
    minimized?: boolean;
    showQtyCheckbox: boolean;
    quickEdit: boolean;
    isInOrder: boolean;
    deleteDisabled?: boolean;
    qty: number;
    itemId: string;
    itemUid: string;
    itemIdToEdit: string;
    hideSwitcher?: boolean;
    isNoGap?: boolean;
    isOrderDetails?: boolean;
}

enum OrderActionType {
    ADD_ITEM = 'add',
    DELETE_ITEM = 'delete',
    EDIT_QTY_ITEM = 'edit',
}

export const CatalogItemCheckbox = ({
    isActive,
    minimized,
    hideSwitcher,
    showQtyCheckbox,
    deleteDisabled,
    itemId,
    itemUid,
    itemIdToEdit,
    quickEdit,
    isInOrder,
    qty,
    isNoGap,
    isOrderDetails,
}: CatalogItemCheckboxProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const canEditCatalog = useSelector(getUserCanEditPropertyCatalog);
    const selectedOrderId = useSelector(getSelectedOrderId);

    const [loading, setLoading] = useState(false);

    const redirectOnNotFound = useCallback(
        () => navigate(getRouteNotFound()),
        [navigate],
    );

    const updateOrder = useDebouncedCallback(
        async (
            params: EditItemsInOrderRequestParams,
            action: OrderActionType,
        ) => {
            dispatch(addItemsToOrderActions.setIsLoadingAddItemsToOrder(true));
            setLoading(true);
            switch (action) {
                case OrderActionType.ADD_ITEM:
                    await dispatch(
                        addItemsToOrderService({
                            data: {
                                items: [
                                    {
                                        catalogItemId: params.itemId,
                                        qty: params.qty,
                                    },
                                ],
                            },
                            orderId: params.orderId,
                        }),
                    );
                    break;

                case OrderActionType.DELETE_ITEM:
                    await dispatch(deleteOrderItem(params));
                    break;

                case OrderActionType.EDIT_QTY_ITEM:
                    await dispatch(editOrderItem(params));
                    break;
                default:
                    break;
            }
            isOrderDetails &&
                (await dispatch(
                    fetchOrder({
                        id: selectedOrderId,
                        onNotFound: redirectOnNotFound,
                    }),
                ));
            dispatch(addItemsToOrderActions.setIsLoadingAddItemsToOrder(false));
            setLoading(false);
        },
    );

    const onItemToggleSelect = useCallback(
        (id: string) => async () => {
            const dataToEdit = {
                orderId: selectedOrderId,
                itemId: itemIdToEdit,
                qty,
            };

            if ((!qty || qty === 0) && !isInOrder) {
                dispatch(
                    catalogItemActions.addMultiselectItem({ id, uid: itemUid }),
                );
                if (quickEdit) {
                    selectedOrderId &&
                        (await updateOrder(
                            { ...dataToEdit, qty: 1 },
                            OrderActionType.ADD_ITEM,
                        ));
                }
            } else {
                dispatch(catalogItemActions.removeMultiselectItem(id));
                if (quickEdit) {
                    selectedOrderId &&
                        (await updateOrder(
                            { ...dataToEdit, qty: 0 },
                            OrderActionType.DELETE_ITEM,
                        ));

                    if (id === itemIdToEdit) {
                        dispatch(catalogItemActions.removeItem(id));
                    }
                }
            }
        },
        [
            selectedOrderId,
            itemIdToEdit,
            qty,
            isInOrder,
            dispatch,
            itemUid,
            quickEdit,
            updateOrder,
        ],
    );

    const onSetItemValue = useCallback(
        (id: string) => async (qty: number) => {
            const dataToEdit = {
                orderId: selectedOrderId,
                itemId: itemIdToEdit,
                qty,
            };

            if (qty < 1) {
                if (quickEdit && id === itemIdToEdit) {
                    dispatch(catalogItemActions.removeItem(id));
                } else {
                    dispatch(catalogItemActions.toggleMultiselectItem(id));
                }
            } else {
                dispatch(
                    catalogItemActions.setItemQuantity({ id, value: qty }),
                );
            }
            if (quickEdit) {
                dispatch(
                    addItemsToOrderActions.setIsLoadingAddItemsToOrder(true),
                );
                if (qty >= 1 && qty) {
                    selectedOrderId &&
                        (await updateOrder(
                            dataToEdit,
                            OrderActionType.EDIT_QTY_ITEM,
                        ));
                } else if (qty <= 0) {
                    dispatch(catalogItemActions.removeMultiselectItem(id));
                    selectedOrderId &&
                        (await updateOrder(
                            dataToEdit,
                            OrderActionType.DELETE_ITEM,
                        ));
                }

                dispatch(
                    addItemsToOrderActions.setIsLoadingAddItemsToOrder(false),
                );
            }
        },
        [selectedOrderId, itemIdToEdit, quickEdit, dispatch, updateOrder],
    );

    return !showQtyCheckbox ? (
        <Checkbox
            disableRipple
            sx={{
                display: canEditCatalog ? 'inline-flex' : 'none',
                paddingTop: '0px',
                '& >.MuiSvgIcon-root': {
                    width: '28px',
                    height: '28px',
                },
            }}
            checked={isActive}
            onClick={onItemToggleSelect(itemIdToEdit)}
        />
    ) : (
        <AddCatalogItemWithQty
            minimized={minimized}
            hideSwitcher={hideSwitcher}
            deleteDisabled={deleteDisabled}
            value={qty}
            isNoGap={isNoGap}
            active={isActive}
            onItemToggleSelect={onItemToggleSelect(itemId)}
            onSetValue={onSetItemValue(itemId)}
            loading={loading}
        />
    );
};
