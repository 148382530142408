import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { fetchProperties } from '../services/fetchProperties/fetchProperties';
import { updateDeliveryInstructions } from '../services/updateDeliveryInstructions/updateDeliveryInstructions';
import { Property, PropertySchema } from '../types/PropertySchema';

const initialState: PropertySchema = {
    isLoading: false,
    selectedPropertyIdForReports: '',
    selectedPropertyIdForVendor: '',
};

const updateSelectedPropertyData = function (state: PropertySchema) {
    if (!state.selectedId && !state.selectedData) {
        state.selectedData = state.dataList?.[0];
        state.selectedId = state.selectedData?.id;
        state.selectedPropertyVendorData = state.selectedData;
        state.selectedPropertyIdForVendor = state.selectedData?.id;
    } else if (state.selectedId) {
        const foundId = state.dataList?.find(
            (property: Property) => property.id === state.selectedId,
        );
        const resolvedProperty = foundId || state.dataList?.[0];
        state.selectedData = resolvedProperty;
        state.selectedPropertyVendorData = resolvedProperty;
        state.selectedPropertyIdForVendor = resolvedProperty.id;
    }
};

export const PropertySlice = createSlice({
    name: 'Property',
    initialState,
    reducers: {
        selectProperty: (state, action: PayloadAction<string>) => {
            state.selectedData = state.dataList?.find(
                (property) => property.id === action.payload,
            );
            state.selectedId = action.payload;
        },
        setPropertyId: (state, action: PayloadAction<string>) => {
            state.selectedId = action.payload;
        },
        setPropertyForVendor: (state, action: PayloadAction<string>) => {
            state.selectedPropertyVendorData = state.dataList?.find(
                (property) => property.id === action.payload,
            );
            state.selectedPropertyIdForVendor = action.payload;
        },
        setPropertyIdForReports: (state, action: PayloadAction<string>) => {
            state.selectedReportData = state.dataList?.find(
                (property) => property.id === action.payload,
            );
            state.selectedPropertyIdForReports = action.payload;
        },
        clearStore: (state) => {
            state.dataList = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProperties.pending, (state) => {
                state.error = null;
                state.isLoading = !state.dataList;
            })
            .addCase(
                fetchProperties.rejected,
                (state, action: PayloadAction<string>) => {
                    state.error = action.payload;
                    state.isLoading = false;
                },
            )
            .addCase(
                fetchProperties.fulfilled,
                (state, action: PayloadAction<Property[]>) => {
                    state.dataList = action.payload;
                    updateSelectedPropertyData(state);
                    state.isLoading = false;
                },
            )
            .addCase(
                updateDeliveryInstructions.fulfilled,
                (state, action: PayloadAction<Property>) => {
                    if (action.payload.id) {
                        const propertyIndex = state.dataList.findIndex(
                            (property) => property.id === action.payload.id,
                        );

                        if (propertyIndex !== -1) {
                            state.dataList[propertyIndex] = action.payload;
                            updateSelectedPropertyData(state);
                        }
                    }
                },
            );
    },
});

export const { actions: propertyActions } = PropertySlice;
export const { reducer: propertyReducer } = PropertySlice;
