import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderStatuses } from 'entities/Order/model/consts/orderStatuses';

import { ChangeOrderStatusButton } from './ChangeOrderStatusButton';

interface PlaceOrderButtonProps {
    orderId: string;
    isNoItemsInOrder: boolean;
    isRequiredPoNumberForOrder: boolean;
    hasLoadingPrices: boolean;
}

export const ApproveOrderButton = memo(
    ({
        orderId,
        isNoItemsInOrder,
        isRequiredPoNumberForOrder,
        hasLoadingPrices,
    }: PlaceOrderButtonProps) => {
        const { t } = useTranslation();

        return (
            <ChangeOrderStatusButton
                newStatus={OrderStatuses.APPROVED}
                orderId={orderId}
                messageOnSuccess={t('Order approved')}
                variant="contained"
                disabled={
                    isNoItemsInOrder ||
                    isRequiredPoNumberForOrder ||
                    hasLoadingPrices
                }
                isRequiredPoNumberForOrder={isRequiredPoNumberForOrder}
            >
                {isRequiredPoNumberForOrder
                    ? t('PO required')
                    : t('Confirm order')}
            </ChangeOrderStatusButton>
        );
    },
);
